import { useState } from "react"
import { motion, Variants } from "framer-motion"

type Tab = {
  id: string
  label: string
}
const tabs: Tab[] = [
  { id: "unincentivized", label: "Unincentivized" },
  { id: "incentivized", label: "Incentivized" },
]

export const TabSelector = () => {
  const [activeTab, setActiveTab] = useState(tabs[0])

  const handleClick = (e: any, tab: Tab) => {
    e.preventDefault()

    setActiveTab(tab)
  }

  const isSelected = (tab: Tab) => activeTab.id === tab.id

  return (
    <div id="tab-selector" className="flex h-10 w-fit">
      <div className="flex">
        {tabs.map((tab: Tab) => (
          <button
            key={tab.id}
            onClick={(e: any) => handleClick(e, tab)}
            className={`relative w-[135px] pt-2 px-4 pb-4
                text-sm leading-4 font-bold tracking-thin uppercase
                ${isSelected(tab) ? "text-marginalGray-200" : "text-marginalGray-600 hover:text-marginalGray-400"}`}
          >
            {tab.label}
            {isSelected(tab) && (
              <motion.div
                layoutId="indicator"
                className="absolute bottom-0 left-0 right-0 h-[1px] bg-marginalOrange-500"
              />
            )}
          </button>
        ))}
      </div>
    </div>
  )
}
